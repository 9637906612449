/* global.css */
body {
  font-family: 'Poppins', sans-serif; /* Apply Poppins font family to the entire body */
  font-size: 20px; /* Apply a font size of 26px to the entire body */
  background-color: #F0F0F0 !important;
}

h1 {
  font-family: "Vesper", sans-serif;
  margin: 15px;
  color: #030388;
  font-weight: bolder;
  display: flex; /* Use Flexbox */
  justify-content: center; /* Center horizontally */
}

