.footer {
  display: flex;
  justify-content: space-between; /* Align left and right content */
  align-items: center;
  padding: 20px 50px;
  background-color: #f8f8f8; /* Off-white background */
  color: #333; /* Dark text color */
  border-top: 1px solid #ccc;
}

.footer-left {
  flex: 1; /* Takes up left side space */
}

.footer-left h3 {
  margin-bottom: 10px;
  color: #1310bc; /* Blue for headings */
}

.footer-left p {
  margin: 5px 0;
  font-size: 14px;
  color: #555;
}

.footer-links {
  margin-top: 15px;
}

.footer-link {
  margin-right: 15px;
  text-decoration: none;
  font-size: 14px;
  color: #1310bc; /* Blue link color */
  transition: color 0.3s ease;
}

.footer-link:hover {
  text-decoration: underline;
  color: #0077b5; /* Slightly brighter blue on hover */
}

.footer-right {
  flex: 1; /* Takes up right side space */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.footer-right .social-icons {
  margin-top: 10px;
}

.footer-right .social-icons a {
  margin-left: 15px;
  color: #1310bc; /* Blue for social icons */
  font-size: 1.5rem; /* Adjust icon size */
  transition: color 0.3s ease;
}

.footer-right .social-icons a:hover {
  color: #0077b5; /* Change color on hover, can customize per platform */
}

.social-icons i {
  font-size: 24px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer {
      flex-direction: column;
      align-items: flex-start;
      padding: 20px 30px;
  }

  .footer-right {
      align-items: flex-start;
      margin-top: 20px;
  }

  .footer-links {
      margin-top: 10px;
  }
}
