.buttons-right {
    margin-left: auto; 
    /* Push the buttons to the right */
}


.button-login {
    margin: 10px;
}

.button-getstarted {
    margin: 10px;
}

  .navbar-padding {
    padding: 10px; /* Add padding to the navbar */
  }
  
  .search-container {
    display: flex;
    justify-content: center; /* Horizontally center items */
    align-items: center;
    flex-grow: 1;
    font-family: 'Poppins', sans-serif; /* Apply Poppins font family to the entire body */
    font-size: 26px; 
  }

  .navbar-padding {
    padding-left: 10px !important;
  }
  
  .search-container input {
    margin-right: 10px; 
    width: 0%; /* Add margin between input and button */
    max-width: 600px !important;
  }
  .text-white {
    flex-shrink: 0;
    text-decoration: none;
    padding: 1px 8px 0 6px;
    margin: 1px;
    display: flex;
    position: static;
  }

/* .search-container {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 10px;
} */
/* navbar.css */

.navbar-toggler {
  border-color: white !important;
}

.btn-outline-light {
  color: white !important;
  border-color: white !important;
}

.btn-outline-light:hover {
  background-color: white !important;
  color: #4de !important;
}

.navbar {
  background-color: rgb(46, 87, 169) !important; /* Set background color to royal blue */
  font-family: 'Montserrat' !important, sans-serif;
  font-size: '10px' !important;
}

.navbar-nav .nav-link {
  color: white !important; /* Set text color to white */
}

.nav-link-white {
  color: white !important; /* Set text color to white */
}

.input-smaller {
  width: 80px !important;
  height: 10px;
  font-size: 10px !important;
  flex-direction: column;
  font-family: 'Rubik' !important, sans-serif;
}

.input-smaller-B {
  width: 80px !important;
  height: 30px;
  margin-top: 10px;
  font-size: 10px !important;
}

/* Ensure base styles are set as needed */
.navbar-brand img {
  width: 80px; /* Default width for non-mobile views */
}

.nav-link {
  font-size: 5px; /* Default font size for non-mobile views */
  font-family: 'Montserrat';
}

/* Media query for mobile views */
@media (max-width: 768px) {
  .navbar-brand img {
      width: 20px; /* Adjusted width on mobile */
  }

  .nav-link {
      font-size: 10px; /* Corrected font size for mobile views */
      font-family: 'Montserrat';
  }

  .nav-link-custom {
    font-size: 5px; /* Default font size for non-mobile views */
    font-family: 'Montserrat';
    margin-top: 5px;
  }

  .nav-link-custom {
    font-size: 10px; /* Adjusted font size for mobile views */
}
}



